<template>
  <Container class="wistia-embed-container" :size="width">
    <div class="wistia-embed">
      <Player
        :wistia-id="id"
        :popover="popover"
        :no-aspect-ratio="noAspectRatio"
      />
    </div>
  </Container>
</template>

<script>
  import Player from "./player"
  import Container from "~/components/Container"

  export default {
    components: { Container, Player },
    props: {
      margin: {
        type: Boolean,
        default: true,
      },
      popover: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String,
        required: true,
      },
      width: {
        type: String,
        default: "large",
        validator: (value) => {
          return ["small", "large"].includes(value)
        },
      },
      // If this is set to true the components needs a fixed with wrap
      noAspectRatio: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style lang="scss">

  .wistia_focus {
    &:before {
      position: absolute;
      display: block;
      content: " ";
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: inset 0 0 0 4px $blue-800;
      z-index: 50;
      box-sizing: border-box;
    }
  }
</style>
